@font-directory-path: '../fonts/';

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-Medium.eot');
  src: local('Raleway Medium'), local('Raleway-Medium'),
  url('@{font-directory-path}Raleway-Medium.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-Medium.woff') format('woff'),
  url('@{font-directory-path}Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-Regular.eot');
  src: local('Raleway'), local('Raleway-Regular'),
  url('@{font-directory-path}Raleway-Regular.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-Regular.woff') format('woff'),
  url('@{font-directory-path}Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-MediumItalic.eot');
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
  url('@{font-directory-path}Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-MediumItalic.woff') format('woff'),
  url('@{font-directory-path}Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-BoldItalic.eot');
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
  url('@{font-directory-path}Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-BoldItalic.woff') format('woff'),
  url('@{font-directory-path}Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-SemiBoldItalic.eot');
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
  url('@{font-directory-path}Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-SemiBoldItalic.woff') format('woff'),
  url('@{font-directory-path}Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-SemiBold.eot');
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url('@{font-directory-path}Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-SemiBold.woff') format('woff'),
  url('@{font-directory-path}Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-Italic.eot');
  src: local('Raleway Italic'), local('Raleway-Italic'),
  url('@{font-directory-path}Raleway-Italic.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-Italic.woff') format('woff'),
  url('@{font-directory-path}Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('@{font-directory-path}Raleway-Bold.eot');
  src: local('Raleway Bold'), local('Raleway-Bold'),
  url('@{font-directory-path}Raleway-Bold.eot?#iefix') format('embedded-opentype'),
  url('@{font-directory-path}Raleway-Bold.woff') format('woff'),
  url('@{font-directory-path}Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
