@import 'variables';

body {
  font-family: @font-family !important;
  font-feature-settings: @font-feature-settings-base !important;

  a {
    text-decoration: underline;
    color: @text-color;
    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: @text-color;
  }

  .ant-tooltip.close-event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid @red-color;
    background-color: @chablis-color;
    color: @red-color;
  }

  .ant-tooltip.event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
    color: @primary-color;
    background: @solitude-color;
    border: 1px solid @primary-color;
  }

  .ant-form-item-label > label .ant-form-item-tooltip {
    margin-left: 8px;
  }

  .ant-tooltip.close-event.ant-tooltip-placement-right
    .ant-tooltip-content
    .ant-tooltip-arrow
    .ant-tooltip-arrow-content {
    background-color: @red-color;
  }

  .ant-tooltip.event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
    background-color: @primary-color;
  }

  .w-100 {
    width: 100%;
  }

  .ant-affix {
    z-index: 11;
  }

  .react-resizable {
    position: relative;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
  }

  .rules-form-item {
    width: 100%;
  }

  .report-modal {
    .ant-modal-confirm-btns {
      display: none;
    }
  }

  .queryBuilder {
    width: 100%;
    &.queryBuilder-invalid.queryBuilder-touched {
      background-color: rgba(255, 0, 0, 0.1);
      > .ruleGroup {
        border-color: red;
      }
    }
    .rule-group-remove {
      cursor: pointer;
      margin: 0;
    }
    .ruleGroup-combinators {
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      width: 70px;
      padding: 2px 8px 2px;
      border-radius: 8px;
      border: none;
      color: white;
      background: #1890ff;
    }
    .ruleGroup-addRule,
    .ruleGroup-addGroup {
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      padding: 2px 16px 2px;
      border-radius: 8px;
      border: none;
      color: white;
      background: #1890ff;
    }
    & > .ruleGroup {
      padding: 16px;
    }
    .ruleGroup {
      background-color: rgba(0, 0, 0, 0.1);
      gap: 1rem;
    }
    .rule-fields,
    .rule-operators,
    .rule-value {
      height: 32px;
      font-size: 14px;
      min-width: 100px;
      padding: 2px 8px 2px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
    }
    .rule-fields {
      min-width: 200px;
    }
    .ruleGroup .rule {
      gap: 1rem;
    }
    .rule-value {
      min-width: 180px;
    }
    .rule-value-multiple {
      min-width: 180px;
      height: auto;
      .ant-select-selector {
        padding: 1px 6px;
        border-radius: 8px;
      }
      .ant-select-selection-overflow {
        gap: 2px;
      }
      .ant-select-selection-item {
        border-radius: 8px;
      }
    }
    .rule-value-time-range.ant-picker.ant-picker-range {
      width: auto;
    }
    .rule-value-number {
      width: auto;
    }
  }
}

