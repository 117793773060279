body {
  .ant-modal-content {
    border-radius: 24px;
  }

  .ant-modal-header {
    border-radius: 24px;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    display: inline-flex;
  }

  .ant-modal-mask {
    z-index: 1040;
  }

  .ant-modal-wrap {
    z-index: 1041;
  }
}
