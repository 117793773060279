body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  font-feature-settings: 'tnum' !important;
}
body a {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.85);
}
body a:hover {
  text-decoration: underline;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: rgba(0, 0, 0, 0.85);
}
body .ant-tooltip.close-event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #FF6960;
  background-color: #FFF0EF;
  color: #FF6960;
}
body .ant-tooltip.event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-inner {
  color: #1890ff;
  background: #EBF6FF;
  border: 1px solid #1890ff;
}
body .ant-form-item-label > label .ant-form-item-tooltip {
  margin-left: 8px;
}
body .ant-tooltip.close-event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: #FF6960;
}
body .ant-tooltip.event.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: #1890ff;
}
body .w-100 {
  width: 100%;
}
body .ant-affix {
  z-index: 11;
}
body .react-resizable {
  position: relative;
}
body .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}
body .rules-form-item {
  width: 100%;
}
body .report-modal .ant-modal-confirm-btns {
  display: none;
}
body .queryBuilder {
  width: 100%;
}
body .queryBuilder.queryBuilder-invalid.queryBuilder-touched {
  background-color: rgba(255, 0, 0, 0.1);
}
body .queryBuilder.queryBuilder-invalid.queryBuilder-touched > .ruleGroup {
  border-color: red;
}
body .queryBuilder .rule-group-remove {
  cursor: pointer;
  margin: 0;
}
body .queryBuilder .ruleGroup-combinators {
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  width: 70px;
  padding: 2px 8px 2px;
  border-radius: 8px;
  border: none;
  color: white;
  background: #1890ff;
}
body .queryBuilder .ruleGroup-addRule,
body .queryBuilder .ruleGroup-addGroup {
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  padding: 2px 16px 2px;
  border-radius: 8px;
  border: none;
  color: white;
  background: #1890ff;
}
body .queryBuilder > .ruleGroup {
  padding: 16px;
}
body .queryBuilder .ruleGroup {
  background-color: rgba(0, 0, 0, 0.1);
  gap: 1rem;
}
body .queryBuilder .rule-fields,
body .queryBuilder .rule-operators,
body .queryBuilder .rule-value {
  height: 32px;
  font-size: 14px;
  min-width: 100px;
  padding: 2px 8px 2px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
body .queryBuilder .rule-fields {
  min-width: 200px;
}
body .queryBuilder .ruleGroup .rule {
  gap: 1rem;
}
body .queryBuilder .rule-value {
  min-width: 180px;
}
body .queryBuilder .rule-value-multiple {
  min-width: 180px;
  height: auto;
}
body .queryBuilder .rule-value-multiple .ant-select-selector {
  padding: 1px 6px;
  border-radius: 8px;
}
body .queryBuilder .rule-value-multiple .ant-select-selection-overflow {
  gap: 2px;
}
body .queryBuilder .rule-value-multiple .ant-select-selection-item {
  border-radius: 8px;
}
body .queryBuilder .rule-value-time-range.ant-picker.ant-picker-range {
  width: auto;
}
body .queryBuilder .rule-value-number {
  width: auto;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Medium.eot');
  src: local('Raleway Medium'), local('Raleway-Medium'), url('./fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Medium.woff') format('woff'), url('./fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Regular.eot');
  src: local('Raleway'), local('Raleway-Regular'), url('./fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Regular.woff') format('woff'), url('./fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-MediumItalic.eot');
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'), url('./fonts/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-MediumItalic.woff') format('woff'), url('./fonts/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-BoldItalic.eot');
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'), url('./fonts/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-BoldItalic.woff') format('woff'), url('./fonts/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-SemiBoldItalic.eot');
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'), url('./fonts/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-SemiBoldItalic.woff') format('woff'), url('./fonts/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-SemiBold.eot');
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('./fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-SemiBold.woff') format('woff'), url('./fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Italic.eot');
  src: local('Raleway Italic'), local('Raleway-Italic'), url('./fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Italic.woff') format('woff'), url('./fonts/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Bold.eot');
  src: local('Raleway Bold'), local('Raleway-Bold'), url('./fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Bold.woff') format('woff'), url('./fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu.ant-menu-inline-collapsed {
  padding: 16px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
  margin-bottom: 8px;
  text-align: center;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected div {
  background-color: #ECF2F7;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 48px;
  min-width: 48px;
}
@media (max-width: 1600px) {
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 34px;
    line-height: 32px;
    min-width: 32px;
  }
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  line-height: 44px;
}
.ant-menu > .ant-menu-item-divider {
  background-color: #d9d9d9;
  margin: 24px 0;
}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
.ant-popover.menu-popover {
  /* 85% text color in rgba */
}
.ant-popover.menu-popover .ant-popover-arrow-content,
.ant-popover.menu-popover .ant-popover-inner-content {
  color: white;
  background-color: #1890ff;
}
.ant-popover-inner-content {
  border-radius: 8px;
}
.ant-popover-arrow {
  width: 8px;
  height: 10px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 2px;
}
.ant-layout-sider-zero-width-trigger {
  top: 0;
  margin-right: -16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-title-content {
  vertical-align: top;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  padding: 16px;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title {
  padding: 0;
}
.ant-row.ant-form-item {
  margin-bottom: 12px;
}
.ant-row.ant-form-item.compact {
  margin-bottom: 3px;
}
@media (max-width: 1600px) {
  .ant-row.ant-form-item {
    margin-bottom: 8px;
  }
}
.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 0;
}
body .ant-form-large .ant-form-item-label > label {
  height: auto;
}
@media (max-width: 1600px) {
  body .ant-form-large .ant-form-item-control-input {
    min-height: 24px;
  }
}
body .ant-input {
  width: 100%;
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: white;
  font-feature-settings: 'tnum';
}
body .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
body textarea.ant-input {
  padding: 8px 12px;
  border-bottom-right-radius: 4px;
}
body .ant-input-affix-wrapper-lg {
  height: 40px;
  padding: 0 11px;
}
body .ant-input-affix-wrapper-lg .ant-input-lg {
  height: 38px;
}
body .ant-input-lg {
  height: 40px;
}
body .ant-input-number {
  width: 100%;
  border-radius: 8px;
  font-feature-settings: 'tnum';
}
body .ant-input-number-group-addon {
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  padding-top: 2px;
}
body .ant-input-number-lg {
  height: 40px;
  line-height: 1.3;
}
body .ant-input-number-lg input {
  height: 40px;
}
body .ant-input-number-input {
  border-radius: 8px;
}
body .ant-input-number-handler-wrap {
  border-radius: 0 8px 8px 0;
}
body .ant-input-affix-wrapper {
  border-radius: 12px;
}
body .ant-input-affix-wrapper > input.ant-input {
  border-radius: 0;
}
body .ant-input-password input {
  border-radius: 0;
}
body .react-tel-input .form-control[disabled] {
  background: #f5f5f5;
}
body .react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px;
}
body .react-tel-input .selected-flag {
  border-radius: 12px 0 0 12px;
}
@media (max-width: 1600px) {
  body .ant-input-affix-wrapper-lg {
    height: 32px;
    padding: 0 8px;
  }
  body .ant-input-affix-wrapper-lg .ant-input-lg {
    height: 28px;
    line-height: 32px;
    font-size: 14px;
  }
  body .ant-input-lg {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  body .ant-input-number-lg {
    height: 32px;
  }
  body .ant-input-number-lg input {
    height: 32px;
  }
}
body .ant-modal-content {
  border-radius: 24px;
}
body .ant-modal-header {
  border-radius: 24px;
}
body .ant-modal-confirm-body .ant-modal-confirm-title {
  display: inline-flex;
}
body .ant-modal-mask {
  z-index: 1040;
}
body .ant-modal-wrap {
  z-index: 1041;
}
body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: white;
}
body .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::placeholder {
  color: rgba(0, 0, 0, 0.85);
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
@media (max-width: 1600px) {
  body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
  }
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 40px;
}
@media (max-width: 1600px) {
  body .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 32px;
  }
}
body .ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
@media (max-width: 1600px) {
  body .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 24px;
    line-height: 24px;
  }
}
body .ant-select-multiple .ant-select-selector {
  padding: 1px 4px;
}
@media (max-width: 1600px) {
  body .ant-select-multiple .ant-select-selector {
    padding: 0 2px;
  }
}
body .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
@media (max-width: 1600px) {
  body .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 24px;
    line-height: 24px;
  }
}
body .ant-select-lg {
  height: 40px;
}
@media (max-width: 1600px) {
  body .ant-select-lg {
    height: 32px;
    font-size: 14px;
  }
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
@media (max-width: 1600px) {
  body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}
@media (max-width: 1600px) {
  body .ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 26px;
  }
}
body .ant-radio-button-wrapper {
  height: 40px;
  font-size: 14px;
  line-height: 38px;
}
body .ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0;
}
body .ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px;
}
@media (max-width: 1600px) {
  body .ant-radio-button-wrapper {
    height: 32px;
    font-size: 14px;
    line-height: 28px;
  }
}
body .ant-btn-primary[disabled],
body .ant-btn-primary[disabled]:hover,
body .ant-btn-primary[disabled]:focus,
body .ant-btn-primary[disabled]:active {
  color: #94979B;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
body .ant-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
body .ant-btn.ant-btn-primary {
  padding: 0 16px;
}
body .ant-btn.ant-btn-lg {
  height: 48px;
}
@media (max-width: 1600px) {
  body .ant-btn.ant-btn-lg {
    height: 32px;
    font-size: 14px;
  }
}
@media (max-width: 1600px) {
  body .ant-btn.ant-btn-sm {
    height: 24px;
  }
}
body .ant-checkbox + span {
  padding: 0 12px;
  position: relative;
  top: -4px;
}
body .ant-checkbox-inner {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: white;
}
body .ant-checkbox-inner:after {
  left: 25%;
  width: 6px;
  height: 14px;
  top: 45%;
}
body .ant-form-item input[type='radio'],
body .ant-form-item input[type='checkbox'] {
  height: 24px;
  width: 24px;
}
@media (max-width: 1600px) {
  body .ant-checkbox + span {
    padding: 0 8px;
    top: -1px;
  }
  body .ant-checkbox-inner {
    height: 16px;
    width: 16px;
  }
  body .ant-checkbox-inner:after {
    width: 4px;
    height: 8px;
  }
  body .ant-form-item input[type='radio'],
  body .ant-form-item input[type='checkbox'] {
    height: 16px;
    width: 16px;
  }
}
body .ant-picker {
  border-radius: 4px;
  border-color: #d9d9d9;
  background-color: white;
  font-feature-settings: 'tnum';
}
body .ant-picker.ant-picker-range {
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px;
}
body .ant-picker.ant-picker-small {
  height: 32px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
body .ant-picker.ant-picker-large {
  height: 40px;
  border-radius: 12px;
}
body .ant-picker .ant-picker-footer {
  justify-content: right;
  align-items: center;
  display: flex;
  height: 36px;
}
body .ant-picker-dropdown {
  font-feature-settings: 'tnum';
}
@media (max-width: 1600px) {
  body .ant-picker.ant-picker-range {
    padding: 4px 8px;
  }
  body .ant-picker-large {
    padding: 2px 8px 2px;
  }
  body .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }
  body .ant-picker.ant-picker-large {
    height: 32px;
    border-radius: 8px;
  }
}
body .ant-picker-time-panel-column::after {
  height: 0;
}
.ant-table table {
  border: 4px solid #fff;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  font-size: 18px;
  font-feature-settings: 'tnum';
}
.ant-table table .ant-table-selection-col {
  width: 58px;
}
.ant-table table .ant-table-thead > tr > th {
  background-color: #fafafa;
  border-bottom: 0;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
@media (max-width: 1600px) {
  .ant-table table .ant-table-thead > tr > th {
    padding: 10px 8px;
    font-size: 14px;
  }
}
.ant-table table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 12px;
}
.ant-table table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}
.ant-table table .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
.ant-table table .ant-table-tbody > tr {
  background-color: white;
}
.ant-table table .ant-table-tbody > tr.draft {
  background-color: #f7f6ec;
}
.ant-table table .ant-table-tbody > tr > td {
  border-color: #d9d9d9;
  padding: 16px;
  line-height: 24px;
}
@media (max-width: 1600px) {
  .ant-table table .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
}
.ant-table table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0;
}
.ant-table table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0;
}
@media (max-width: 1600px) {
  .ant-table table {
    font-size: 14px;
  }
}
.ant-table-body table {
  border: 4px solid #fff;
  border-radius: 0 0 12px 12px;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 6px;
}
.ant-pagination-item {
  border-radius: 6px;
}
.ant-pagination-item a {
  text-decoration: none;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-weight: 600;
  line-height: 1.3;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 24px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab.ant-tabs-tab-active {
  color: #38A5FF;
}
@media (max-width: 1600px) {
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
}
.ant-tabs-tab-btn:not(.ant-tabs-tab-active) {
  color: #68717B;
}
.ant-tabs-ink-bar {
  height: 4px;
}
body .ant-popover-inner {
  background-color: transparent;
  box-shadow: none;
}
body .ant-dropdown-menu {
  padding: 16px;
  border-radius: 6px;
}
body .ant-dropdown-menu .ant-dropdown-menu-item,
body .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #68717B;
}
body .ant-dropdown-menu .ant-dropdown-menu-item svg,
body .ant-dropdown-menu .ant-dropdown-menu-submenu-title svg {
  height: 20px;
  margin-right: 12px;
  vertical-align: bottom;
}
body .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover svg,
body .ant-dropdown-menu .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-danger:hover svg {
  fill: white;
}
@media (max-width: 1600px) {
  body .ant-dropdown-menu {
    padding: 12px;
  }
  body .ant-dropdown-menu .ant-dropdown-menu-item,
  body .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    font-size: 12px;
  }
  body .ant-dropdown-menu svg {
    height: 12px;
    margin-right: 8px;
  }
}
body .ant-switch .anticon {
  margin-right: 0;
  vertical-align: super;
}
