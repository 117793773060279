@import "~antd/es/style/themes/default";
@import "variables";

@table-selection-column-width: 58px;
@table-border-radius-base: 8px;

.ant-table table {
  border: 4px solid @body-background;
  background-color: @body-background;
  border-radius: 12px 12px 0 0;
  font-size: 18px;
  font-feature-settings: @font-feature-settings-base;

  .ant-table-selection-col {
    width: @table-selection-column-width;
  }

  .ant-table-thead > tr > th {
    background-color: @table-header-bg;
    border-bottom: 0;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: @screen-xxl) {
      padding: 10px 8px;
      font-size: 14px;
    }
  }

  .ant-table-tbody {
    > tr:last-child {
      > td:first-child {
        border-bottom-left-radius: 12px;
      }

      > td:last-child {
        border-bottom-right-radius: 12px;
      }

      > td {
        border-bottom: none;
      }
    }

    > tr {
      background-color: white;

      &.draft {
        background-color: #f7f6ec;
      }

      > td {
        border-color: @border-color-base;
        padding: 16px;
        line-height: 24px;

        @media (max-width: @screen-xxl) {
          padding: 10px 8px;
        }
      }
    }
  }

  > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
  }

  > thead > tr:first-child th:last-child {
    border-top-right-radius: 0;
  }

  @media (max-width: @screen-xxl) {
    font-size: 14px;
  }
}

.ant-table-body {
  table {
    border: 4px solid @body-background;
    border-radius: 0 0 12px 12px;
  }
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 6px;
}

.ant-pagination-item {
  border-radius: 6px;

  a {
    text-decoration: none;
  }
}
