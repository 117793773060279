@import "~antd/es/style/themes/default";
@import "../variables";

@input-padding-horizontal-base: 12px;
@input-padding-vertical-base-base: 8px;
@input-placeholder-color: rgba(0, 0, 0, 0.25);
@input-border-radius: 12px;
@input-number-border-radius: 8px;

body {
  .ant-input {
    width: 100%;
    border-radius: @input-border-radius;
    border-color: @border-color-base;
    background-color: white;
    font-feature-settings: @font-feature-settings-base;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  textarea.ant-input {
    padding: @input-padding-vertical-base-base @input-padding-horizontal-base;
    border-bottom-right-radius: 4px;
  }

  .ant-input-affix-wrapper-lg {
    height: 40px;
    padding: 0 11px;

    .ant-input-lg {
      height: 38px;
    }
  }

  .ant-input-lg {
    height: 40px;
  }

  .ant-input-number {
    width: 100%;
    border-radius: @input-number-border-radius;
    font-feature-settings: @font-feature-settings-base;
  }

  .ant-input-number-group-addon {
    border-radius: @input-number-border-radius 0 0 @input-number-border-radius;
    font-size: 14px;
    padding-top: 2px;
  }

  .ant-input-number-lg {
    height: 40px;
    line-height: 1.3;

    input {
      height: 40px;
    }
  }

  .ant-input-number-input {
    border-radius: @input-number-border-radius;
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 @input-number-border-radius @input-number-border-radius 0;
  }

  .ant-input-affix-wrapper {
    border-radius: @input-border-radius;

    > input.ant-input {
      border-radius: 0;
    }
  }

  .ant-input-password input {
    border-radius: 0;
  }

  .react-tel-input .form-control {
    &[disabled] {
      background: #f5f5f5;
    }
  }

  .react-tel-input .flag-dropdown {
    border-radius: @input-border-radius 0 0 @input-border-radius;
  }

  .react-tel-input .selected-flag {
    border-radius: @input-border-radius 0 0 @input-border-radius;
  }

  @media (max-width: @screen-xxl) {
    .ant-input-affix-wrapper-lg {
      height: 32px;
      padding: 0 8px;

      .ant-input-lg {
        height: 28px;
        line-height: 32px;
        font-size: 14px;
      }
    }

    .ant-input-lg {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
    }

    .ant-input-number-lg {
      height: 32px;

      input {
        height: 32px;
      }
    }
  }
}
