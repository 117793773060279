// left main menu
@import "~antd/es/style/themes/default";
@import "variables";

.ant-menu.ant-menu-inline-collapsed {
  padding: 16px;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: @box-shadow-base;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
  margin-bottom: 8px;
  text-align: center;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  div {
    background-color: #ECF2F7;
  }
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 48px;
  min-width: 48px;

  @media (max-width: @screen-xxl) {
    height: 34px;
    line-height: 32px;
    min-width: 32px;
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  line-height: 44px;
}

.ant-menu > .ant-menu-item-divider {
  background-color: @border-color-base;
  margin: 24px 0;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-popover.menu-popover {
  /* 85% text color in rgba */
  .ant-popover-arrow-content,
  .ant-popover-inner-content {
    color: white;
    background-color: @primary-color;
  }
}

.ant-popover-inner-content {
  border-radius: 8px;
}

.ant-popover-arrow {
  width: 8px;
  height: 10px;
}

.ant-popover-placement-right .ant-popover-arrow, .ant-popover-placement-rightTop .ant-popover-arrow, .ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 2px;
}

.ant-layout-sider-zero-width-trigger {
  top: 0;
  margin-right: -16px;
  color: @text-color;
}

.ant-menu-title-content {
  vertical-align: top;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  padding: 16px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title {
  padding: 0;
}

