@import "~antd/es/style/themes/default";
@import "../variables";

body {
  .ant-switch {
    .anticon {
      margin-right: 0;
      vertical-align: super;
    }
  }
}
